<template>
    <!-- 分拣打印errorsorting -->
    <div class="sorting-container">

        <div class="leftsorting">
            <logotop></logotop>
            <classification style="margin-top:10px" :data="classifylist" @change="changeclassifyFun"></classification>
        </div>
        <div class="rightsorting">

            <sortingform @filter="filterAction" class="sortingformbox" :sortStatusList="filterAllData.sortList" :data="staticdata" @change="changestaticData"
                         @search="refresdata" @refresh="refreshAction"></sortingform>
            <a-spin :spinning="loading">
                <div class="sortingBox" style="margin-top:10px">
                    <a-empty class="nodataempty" v-if="productList.length==0">
                        <span slot="description">暂无数据</span>
                    </a-empty>
                    <div class="sortinginner" v-else @scroll="listenScroll">
                        <div class="titleName">{{messageClass||'全部分类'}}</div>
                        <div v-for="(item,index) in productList" class="producttabItem"
                             :style="{marginRight:(index==5?'0px':'8px')}" @click="go_detail(item)">
                            <product-tab :data="item"
                                         :color="item.sorting_status_id==2?'#34BC00':item.sorting_status_id==1?'#BC7B00':'#BC1D00'"></product-tab>
                        </div>
                        <load-more :status="loadingType"></load-more>
                    </div>
                </div>
            </a-spin>
        </div>
    </div>
</template>

<script>
  import logotop from "@/components/logotop"; //顶部logo 
  import sortingform from "@/components/sortingform"; //顶部查询
  import classification from "@/components/classification"; //顶部查询
  import productTab from "@/components/productTab"; //顶部查询
  import loadMore from "@/components/loadMore"; //顶部查询


  export default {
    name: "sorting",
      inject: ['reload'],
    components: {
      logotop,
      sortingform,
      classification,
      productTab,
      loadMore

    },
    data() {
      return {
        activeKey: ['1'],
        customStyle:
          'background: #fff;color: #010101;box-shadow: 0px 2px 11px 0px rgba(177, 188, 199, 0.15);font-size: 23px;font-weight: 600;bordedr:none;overflow: hidden',
        classifylist: [],
        productList: [],
        loading: false,
        loadingType: 'loading',
        messageClass: "",
        LOCK_STATUS: false,
        staticdata: {
          send_time: this.timedata(),
          page_size: 24,
          cate_id: '',
          name: '',
          page: 1
        },
        filterVisible: false,
        filterAllData: {},
        filterForm: {}
      }

    },
    mounted() {
      this.getclassfiy()
      this.getData('refresh')

    },
    activated() {
    	this.getData('refresh');
    },
    methods: {
        refreshAction() {
          location.reload()
        },
      timedata() {
        var day = new Date()
        var minutes = ('0' + (day.getMinutes())).slice(-2)
        var hours = ('0' + (day.getHours())).slice(-2)
        var seconds = ('0' + (day.getSeconds())).slice(-2)
        var time = hours + ':' + minutes + ':' + seconds
        var date = day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()
        if (!(time < '12:00:00')) { // 不加一天
          day.setTime(day.getTime() + 24 * 60 * 60 * 1000)
          date = day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()
        }
        return date
      },
      changestaticData(e) {
        this.staticdata = e
      },
      refresdata(e) {
        this.staticdata = e
        this.getData('refresh')
      },
      calleArr: function (array) {
        for (var i in array) {
          var data = array[i];
          if (data.children) {
            this.calleArr(data.children) //自己调用自己
          } else {
            data.children = []
          }
        }
      },
      getclassfiy() {
        this.rq.get('/merapi/sorting/v2/common/code-list').then(res => {

          this.classifylist = res.data.cateList
          this.filterAllData = res.data
          // this.calleArr(this.classifylist)
        })
      },
      getData(e) {
          if(e=='refresh'){
              this.staticdata.page=1;
          }
        this.loading = true;
        let p = Object.assign({}, this.staticdata, this.filterForm)
        this.rq.get('/merapi/sorting/v2/sorting/product-rate', {params: p}).then(res => {
          this.loading = false;
          this.LOCK_STATUS = false;
          this.loadingType = res.data.data.length === this.staticdata.page_size ? 'more' : 'nomore';
          if(e=='refresh'){
               this.productList = res.data.data;
          }else{
               this.productList = [...this.productList, ...res.data.data];
          }

        }).catch(err => {
          this.loading = false;
          this.LOCK_STATUS = false;
        })
      },
      listenScroll(e) {
        if (this.LOCK_STATUS) return;
        if (e instanceof Event) {
          var el = e.target;
          var scHeight = el.scrollHeight, scTop = el.scrollTop, clHeight = el.clientHeight;
          //距离底部100px时，开始加载数据
          if (scHeight - scTop > clHeight + 20) return;
        }
        this.LOCK_STATUS = true;
        if (this.loadingType === 'nomore' || this.productList.length === 0) return;
        this.staticdata.page++;
        this.getData();
      },
      expandIcon(props, data) {
        if(data.isActive){
          return <div class="togglestyle"><a-icon type="minus-circle" class="icon"/>收起</div>
        }else{
          return <div class="togglestyle"><a-icon type="plus-circle" class="icon"/>展开</div>
        }
      },
      changeclassifyFun(e) {
        this.staticdata.cate_id = e.value;
        this.messageClass = e.label
        this.getData('refresh');
      },
      go_detail(item) {
        var optionsData = {
          product_name: item.title,
          commodity_name: item.product_id,
          unit: item.name,
          send_time: this.staticdata.send_time,
            sku_id: item.sku_id
        };
        this.$router.push({
          name: 'SortingPrint',
          // 只是把query改了，其他都没变
          query: optionsData
        })
        // }
        // this.$router.push('/sorting_print')
      },



      filterAction() {
        console.log('xxx')
        this.filterVisible = true
      },
      filterClose() {
        this.filterVisible = false
      },
      filterSaveAction() {
        this.filterVisible = false
        this.getData('refresh')
      },
      filterResetAction() {
        this.filterForm = {}
        this.filterVisible = false
        this.getData('refresh')
      }
    }
  }
</script>

<style scoped lang="less">
    .titleName {
        font-size: 23px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #010101;
        margin-top: 12px;
        margin-bottom: 22px;
    }

    .producttabItem {
        display: inline-block;
        margin-bottom: 10px
    }

    .togglestyle {
        font-size: 26px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #010101;
        line-height: 32px;

        .icon {
            color: #203EA0;
            font-size: 26px;
            margin-right: 15px;
        }
    }

    .leftsorting {
        width: 205px;
        margin-right: 10px;
    }

    .rightsorting {
        width: 1047px;

    }

    .sortingBox {
        width: 1047px;
        height: 640px;
        position: relative;
        overflow: hidden;

        .nodataempty {
            transform: translate(-50%, -50%);
            position: absolute;
            left: 50%;
            top: 50%;

        }

        .sortinginner {
            height: 640px;
            position: absolute;
            left: 0;
            top: 0;
            right: -17px;
            bottom: 0;
            overflow-x: hidden;
            overflow-y: scroll;
        }
    }

    .sorting-container {
        background: #F1F2F2;
        width: 100%;
        display: flex;
        height: 800px;
        box-shadow: 0px 2px 11px 0px rgba(177, 188, 199, 0.15);
        padding: 10px;
    }

    .sortingformbox {

    }




</style>
<style>
    .option-class {
        height: 40px;
        display: flex;
        align-items: center;
        font-size: 20px;
    }
    .ant-collapse > .ant-collapse-item {
        box-shadow: 0px 2px 11px 0px rgba(177, 188, 199, 0.15);
        border-bottom: none;
        margin-bottom: 2px;
    }

    .ant-collapse-content {
        border-top: none;
    }

    .sortingBox .ant-collapse-content > .ant-collapse-content-box {
        padding: 10px 0px;
        min-height: 392px;
    }

    .sortingBox .ant-collapse-content {
        background: #F1F2F2;
    }

    .sortingBox .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
        font-size: 26px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #010101;
    }

    .sortingBox .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        line-height: 30px;
        font-size: 23px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #010101;
        height: 60px;
    }

    .sortingBox .ant-collapse {
        border: none;
        box-shadow: none;
    }

</style>

